import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Container, Row, Col, Nav, Navbar, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";

import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import { useSelector } from "react-redux";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";

import TelegramIconNew from "../assets/images/social/telegram.png";

import SearchIcon from "../assets/images/icons/find.png";
import { AiOutlineClose } from "react-icons/ai";

import AppDownload from "../assets/images/icons/download.png";

import EzugiIconew from "../assets/images/icons/ezugiivon.png";
import SlotsIconnew from "../assets/images/icons/jackpot.png";

import GamesSearchModal from "../views/components/GamesSearchModal";
import { getNotifications } from "../redux/app/actions";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const isApk = localStorage.getItem("isApk") === "true";
  const { appDetails } = useSelector((state) => state.app);
  const [visible, setVisible] = useState(true);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  useEffect(() => {
    loadNotifications();
  }, []);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 14,
    type: "inc",
  });

  useEffect(() => {
    if (visible) {
      document.body.classList.add("welcome_note");
    } else {
      document.body.classList.remove("welcome_note");
    }
  }, [visible]);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = ["betby", "sports-exchange"];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="headerRight">
                    <div className="searchbar d-none">
                      <Form>
                        <Form.Group className="position-relative">
                          <Form.Control
                            type="text"
                            placeholder="Search your favourite games"
                          />
                          <span>
                            <img src={SearchIcon} />
                          </span>
                        </Form.Group>
                      </Form>
                    </div>

                    {(!isApk && (
                      <a
                        href="javascript:void(0)"
                        className="headicons support apkdownload"
                      >
                        <img src={AppDownload} /> Download APK
                      </a>
                    )) ||
                      null}
                  </div>
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                  <div className="middle-part d-none">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/">
                              <img src={HomeIConNew} />
                              Home
                            </Nav.Link>
                            <Nav.Link href="sports/cricket" className="">
                              <div>
                                <img src={SportsIconNew} />
                              </div>
                              Exchange
                            </Nav.Link>

                            <Nav.Link href="/sign-in">
                              <div className="animate-img">
                                <div className="enter-animate active">
                                  <div className="spin-anim">
                                    <img
                                      src={casinoround}
                                      className="img-spinbg"
                                    />
                                    <img
                                      src={casinogame}
                                      className="img-turntable"
                                    />
                                    <img
                                      src={casinopoint}
                                      className="img-pointer"
                                    />
                                  </div>
                                </div>
                              </div>
                              Casino
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img src={EzugiIconNew} />
                              Ezugi
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img src={EvolutionIconNew} />
                              Evolution
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img src={CashierIconNew} />
                              Cashier
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>
                  <div className="headerRight headerrightsec">
                    <GamesSearchModal />

                    <ul className="social d-none">
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIconNew} />
                        </a>
                      </li>
                    </ul>
                    <ul className="d-flex d-md-none mx-2"></ul>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      Login
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup btn-secondary ms-2"
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                  <li>
                    <a
                      href="/"
                      className={activePage == "betby" ? "active" : ""}
                    >
                      <img
                        src={BotHeadIcon1}
                        alt="bottom head link icon"
                        className="inplay"
                      />
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/beforeexchange"
                      className={activePage == "betby" ? "active" : ""}
                    >
                      <img
                        src={BotHeadIcon1}
                        alt="bottom head link icon"
                        className="inplay"
                      />
                      <span>Inplay</span>
                    </a>
                  </li>
                  <li>
                    <a href="/betby">
                      <img
                        src={EzugiIconew}
                        alt="bottom head link icon"
                        className=""
                      />
                      <span>Sportsbook</span>
                    </a>
                  </li>
                  <li>
                    <a href="/beforeprovider">
                      <img
                        src={SlotsIconnew}
                        alt="bottom head link icon"
                        className=""
                      />
                      <span>Casino</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/beforeexchange"
                      className={
                        activePage == "sports-exchange" ? "active" : ""
                      }
                    >
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>Exchange</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/promotions"
                      className={activePage == "betby" ? "active" : ""}
                    >
                      <img
                        src={BotHeadIcon1}
                        alt="bottom head link icon"
                        className="inplay"
                      />
                      <span>Promotions</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/blog"
                      className={activePage == "blog" ? "active" : ""}
                    >
                      <img
                        src={BotHeadIcon1}
                        alt="bottom head link icon"
                        className="inplay"
                      />
                      <span>Blogs</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Header;
